import React, { useState } from "react";

/* tried bith types  */

const KarmaNodal = (props) => {
  return (
    <section {...props}>
      <div className="grey-background">
        {/* <h2 className="requestDemoHeader ta-c">KarmaLife's Nodal Officer</h2> */}
        <div className="contact-form requestDemoParagraph">
          <div className="">
            <div
              style={{
                fontSize: 24,
                color: "#2F6852",
                fontWeight: 700,
                marginBottom: "20px",
                marginTop: "10px",
              }}
            >
              KarmaLife's Nodal Officer
              <br />
              Details:
            </div>
            Pallavi Bollampally
            <br />
            <>
              {" "}
              {/* {item.AddressTagLine} */}
              {/* <br /> */}
              Address: Urban vault 1497, Ground Floor
              <br />
              19th Main Rd, Sector 1,
              <br />
              HSR Layout, Bangalore,Karnataka 560102
              <br />{" "}
            </>
            <>
              {" "}
              Email: grievance@karmalife.ai
              <br />
            </>
            Phone:9986642141
          </div>
        </div>
      </div>
    </section>
  );
};

export default KarmaNodal;
