import React, { useState } from "react";

/* tried bith types  */

const NodalSection = (props) => {
  console.log("props$$$$", props);
  const officerData = [
    {
      id: 1,
      headline: "Innofin's Nodal officer",
      clientName: "Pratik K",
      AddressTagLine: "Manager-Compliance & Finance",
      Address: "1006 DLH Park SV Road",
      Address1: "Goregaon West Mumbai",
      Address2: "Mumbai-400062",
      Email: "cs@lendenclub.com",
      PhoneNumber: "022-48913091",
    },
    {
      id: 2,
      headline: "Akara Capital's Nodal officer",
      clientName: "Mr. Varun Chhabra",
      AddressTagLine: "Manager-Compliance & Finance",
      Address: "CRC-2, 1st Floor",
      Address1: "Khasra No.337",
      Address2: "Mehrauli-Gurgaon Rd, Sultanpur,New Delhi",
      Email: "cofficer@akaracap.com",
      PhoneNumber: "7065254281",
    },
    {
      id: 3,
      headline: "Lendbox Nodal officer",
      clientName: "Mrs. Tamanna Mehta",
      AddressTagLine: "Transactree Technologies pvt. ltd.",
      Address: "L-92, second floor, above punjab woolen co",
      Address1: "Khasra NO 800",
      Address2: "New Delhi - 110 067",
      Email: "",
      PhoneNumber: "9311393600",
    },
    {
      id: 4,
      headline: "Apollo's Nodal Officer",
      clientName: "Prachi Jain",
      Email: "grievanceredressal@apollofinvest.com",
      PhoneNumber: "022-62231667/68",
    },
    {
      id: 5,
      headline: "Avanti Nodal officer",
      clientName: "Mr. Saurabh Kumar",
      Email: "saurabh.kumar@avantifinance.in",
      PhoneNumber: "+91 98450 93467",
    },
    {
      id: 5,
      headline: "Avanti Grievance officer",
      clientName: "Mr. Sunil K Tadepalli",
      Email: "sunil.kumar.t@avantifinance.in",
      PhoneNumber: "+91 98803 65147",
    },
    {
      id: 6,
      headline: "Niyogin Fintech Limited",
      clientName: "Mr. Noorallah Charania",
      AddressTagLine: "Neelkanth Corporate IT Park,",
      Address: "311/312, 3rd Floor Kirol Road,",
      Address1: " Vidyavihar (W),",
      Address2: "Mumbai - 400086",
      Email: "noorallah.charania@niyogin.in",
      PhoneNumber: "022 62514646",
    },
    {
      id: 7,
      headline: "Lord Fincap Limited",
      clientName: "Mr. Ashish Gupta",
      Email: "info@lordfincap.com",
      PhoneNumber: "9911911112",
    },
    {
      id: 8,
      headline: "AnyTimeLoan.in",
      clientName: "Mr. Nishant K",
      AddressTagLine: "Trendz Hub 1st Floor",
      Address: "401 Vittal Rao nagar road",
      Address1: "Hitec City Gafoornagar Madhapur",
      Address2: "Hyderabad, Telangana 500081",
      Email: "info@anytimeloan.in",
      PhoneNumber: "9848151200",
      OtherComments: "8 am to 11 pm (365 days)",
    },
    {
      id: 9,
      headline: " Trillionloans Fintech Private Limited",
      clientName: "Mr. Hardik Nandani",
      AddressTagLine: "705, Kanakia Boomerang Building",
      Address: "7th Floor, Unit No. B2",
      Address1: "Yadav Nagar, Chandivali,",
      Address2: "Powai, Mumbai - 400072, Maharashtra",
      Email: "grievances@trillionloans.com, nodalofficer@trillionloans.com",
      PhoneNumber: "8851682895",
    },
  ];

  console.log("props of NodalSection", props);
  return (
    <section {...props}>
      <div className="grey-background">
        {officerData.map((item, index) => {
          return (
            <div className="contact-form requestNodal" key={item.id}>
              {props.selected == item.id && (
                <div className="">
                  <div
                    style={{
                      fontSize: 24,
                      color: "#2F6852",
                      fontWeight: 700,
                      marginBottom: "20px",
                    }}
                  >
                    {item.headline}
                    <br />
                    Details:
                  </div>
                  {item.clientName}
                  <br />
                  {item.AddressTagLine && (
                    <>
                      {" "}
                      {item.AddressTagLine}
                      <br />
                      Address: {item.Address}
                      <br />
                      {item.Address1}
                      <br />
                      {item.Address2}
                      <br />{" "}
                    </>
                  )}
                  {item.Email && (
                    <>
                      {" "}
                      Email: {item.Email}
                      <br />
                    </>
                  )}
                  Phone: {item.PhoneNumber}
                  {item.OtherComments && (
                    <>
                      <br />
                      {item.OtherComments}
                    </>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default NodalSection;
